import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Typography,
  createClasses,
  Colors,
} from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import noMatchesIcon from '../../../images/no-matches-big.svg';
import { Device, DeviceEditData, DeviceTableRow } from './DeviceTableRow';

const classes = createClasses({
  content: {
    padding: '0',
    width: '100%',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    bottom: 0,
  },
  text: {
    display: 'block',
    width: '180px',
    marginTop: '12px',
    color: Colors.Neutral.textWeak,
  },
  algorithmColumn: {
    width: 130,
  },
  valueColumn: {
    textAlign: 'right',
    width: 80,
  },
  intervalColumn: {
    textAlign: 'right',
    width: 90,
  },
  actionColumn: {
    textAlign: 'center',
    width: 100,
  },
});

export type { DeviceEditData, Device };
export type DeviceTableProps = {
  loading: boolean;
  className?: string;
  data: Device[];
  onEdit: (id: string, data: DeviceEditData) => void;
  onDelete?: (id: string) => void;
};
export const DeviceTable: React.FC<DeviceTableProps> = ({
  loading,
  className,
  data,
  onEdit,
  onDelete,
}) => {
  const { t } = useTranslation();

  const headers = [
    t('simulator.devicesTable.headers.device'),
    t('simulator.devicesTable.headers.capability'),
    t('simulator.devicesTable.headers.gateway'),
    t('simulator.devicesTable.headers.algorithm'),
    t('simulator.devicesTable.headers.value'),
    t('simulator.devicesTable.headers.interval'),
    t('simulator.devicesTable.headers.delete'),
    t('simulator.devicesTable.headers.details'),
  ];

  return (
    <Table className={className}>
      {((!loading && data.length > 0) || loading) && headers && (
        <TableHead>
          <TableRow>
            {headers.map((header, idx) => (
              <TableHeadCell
                key={header + idx}
                className={classNames(
                  idx === headers.length - 5 && classes.algorithmColumn,
                  idx === headers.length - 4 && classes.valueColumn,
                  idx === headers.length - 3 && classes.intervalColumn,
                  idx === headers.length - 2 && classes.actionColumn,
                  idx === headers.length - 1 && classes.actionColumn,
                )}
              >
                {header}
              </TableHeadCell>
            ))}
          </TableRow>
        </TableHead>
      )}
      <TableBody>
        {loading && (
          <>
            <TableRow loading>
              <TableCell loading />
              <TableCell loading />
              <TableCell loading />
              <TableCell loading />
              <TableCell loading />
              <TableCell loading />
            </TableRow>
            <TableRow loading>
              <TableCell loading />
              <TableCell loading />
              <TableCell loading />
              <TableCell loading />
              <TableCell loading />
              <TableCell loading />
            </TableRow>
            <TableRow loading>
              <TableCell loading />
              <TableCell loading />
              <TableCell loading />
              <TableCell loading />
              <TableCell loading />
              <TableCell loading />
            </TableRow>
          </>
        )}
        {!loading &&
          data.length > 0 &&
          data.map((row) => (
            <DeviceTableRow
              key={row.simulatedDeviceId}
              {...row}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          ))}
        {!loading && data.length === 0 && (
          <TableRow>
            <TableCell>
              <div className={classes.content}>
                <img alt="no-results" src={noMatchesIcon} />
                <Typography variant="labelSmall" className={classes.text}>
                  {t('simulator.noDevicesForGateway')}
                </Typography>
              </div>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
