import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { docs, Doc } from '../../__generated__/docs';
import { Docs } from './Docs';

export const DocsContainer: React.FC = () => {
  const { page } = useParams();
  const navigate = useNavigate();
  const [doc, setDoc] = useState<Doc | null>(null);

  useEffect(() => {
    if (!page) {
      navigate(`/docs/${docs[0].metadata.slug}`);
    } else {
      const result = docs.find((item) => item.metadata.slug === page);
      if (result) {
        setDoc(result);
        window.scrollTo(0, 0);
      } else {
        navigate(`/not-found`);
      }
    }
  }, [page, navigate]);

  const pages = docs.map((item) => ({
    label: item?.metadata.name,
    path: `/docs/${item?.metadata.slug}`,
  }));

  return <Docs pages={pages} content={doc?.content} />;
};
