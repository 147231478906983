import React, { useCallback, useContext, createContext, useState } from 'react';
import Helmet from 'react-helmet';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';

export interface BreadcrumbLink {
  title: string;
  location: string;
  selected?: boolean;
  onClick?: () => void;
}

interface BreadcrumbContextValue {
  links: BreadcrumbLink[];
  add: (link?: BreadcrumbLink | BreadcrumbLink[]) => () => void;
}

const BreadcrumbContext = createContext<BreadcrumbContextValue>({
  links: [],
  add: () => () => {},
});

export const useBreadcrumb = (
  link?: BreadcrumbLink | BreadcrumbLink[],
): BreadcrumbLink[] => {
  const { add, links } = useContext(BreadcrumbContext);
  useDeepCompareEffectNoCheck(() => add(link), [link]);
  return links;
};

interface BreadcrumbProviderProps {
  children?: React.ReactNode;
}

export const BreadcrumbProvider: React.FC<BreadcrumbProviderProps> = ({
  children,
}) => {
  const [allLinks, setLinks] = useState<BreadcrumbLink[]>([]);
  const add = useCallback(
    (link?: BreadcrumbLink | BreadcrumbLink[]) => {
      if (!link) {
        return () => {};
      }
      const links = Array.isArray(link) ? link : [link];
      setLinks((items) => [...items, ...links]);
      return () => {
        setLinks((items) => items.filter((item) => !links.includes(item)));
      };
    },
    [setLinks],
  );

  const title = allLinks[allLinks.length - 1]?.title;

  return (
    <BreadcrumbContext.Provider value={{ links: allLinks, add }}>
      <Helmet>
        <title>{title ? `${title} - Developer` : 'Developer'}</title>
      </Helmet>
      {children}
    </BreadcrumbContext.Provider>
  );
};
