import React from 'react';
import { Voyager as AppVoyager } from 'graphql-voyager';
import raw from 'raw.macro';
import wait from 'wait';
import { GRAPHQL_URI } from '../../utils/env';

/**
 * Parts of this code is taken from :
 * https://github.com/APIs-guru/graphql-voyager/blob/4108ef98817fd71671e940c968126c29e0b24bf0/src/utils/index.ts#L25
 *
 * The idea is that we load the `voyager.worker.js` from node_modules as string and then
 * create a [Worker](https://developer.mozilla.org/en-US/docs/Web/API/Worker) that is
 * used by the Voyager App.
 *
 * The whole thing gets cached with React.memo, so we don't trigger re-renders, which
 * can break the UI.
 */
const workerJs = raw(
  '../../../node_modules/graphql-voyager/dist/voyager.worker.js',
);
const modifiedWorkerJs = workerJs
  // HACK: to increase viz.js memory size from 16mb to 256mb
  .replace('||16777216;', '||(16777216 * 16);')
  .replace('||5242880;', '||(5242880 * 16);');
const script = new Blob([modifiedWorkerJs], { type: 'application/javascript' });
const url = URL.createObjectURL(script);
const worker = new Worker(url);
const loadWorker = () => Promise.resolve(worker);

// eslint-disable-next-line no-underscore-dangle
const getToken = () => window.__AUTH_TOKEN__;

const fetcher = (query: string): Promise<unknown> => {
  const token = getToken();
  // In case there is no token, don't fail the request, but actually wait for one.
  if (!token) {
    return wait(300).then(() => fetcher(query));
  }
  return fetch(GRAPHQL_URI, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token || ''}`,
    },
    body: JSON.stringify({ query }),
  }).then((response) => response.json());
};

export const CachedAppVoyager = React.memo(() => (
  <AppVoyager introspection={fetcher} loadWorker={loadWorker} />
));

CachedAppVoyager.displayName = 'CachedAppVoyager';
