import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ProtectedRoute } from '@kp/react-sdk';
import { SimulatorGatewayListContainer } from './list/SimulatorGatewayListContainer';
import { NotFoundHandler } from '../NotFoundHandler';
import { SimulatorGatewayDevicesContainer } from './devices/SimulatorGatewayDevicesContainer';
import { SimulatorDeviceCreateContainer } from './create/SimulatorDeviceCreateContainer';

export const SimulatorRoutes: React.FC = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<ProtectedRoute element={<SimulatorGatewayListContainer />} />}
      />
      <Route
        path="/:simulatedGatewayId"
        element={
          <ProtectedRoute element={<SimulatorGatewayDevicesContainer />} />
        }
      />
      <Route
        path="/:simulatedGatewayId/new"
        element={
          <ProtectedRoute element={<SimulatorDeviceCreateContainer />} />
        }
      />
      <Route path="*" element={<NotFoundHandler />} />
    </Routes>
  );
};
