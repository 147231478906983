import React, { useContext, createContext, useState } from 'react';

interface HeaderContextValue {
  title: string;
  setTitle: (title: string) => void;
}

const HeaderContext = createContext<HeaderContextValue>({
  title: '',
  setTitle: () => {},
});

export const useHeader = (): HeaderContextValue => useContext(HeaderContext);

export const HeaderProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [title, setTitle] = useState('');
  return (
    <HeaderContext.Provider value={{ title, setTitle }}>
      {children}
    </HeaderContext.Provider>
  );
};
