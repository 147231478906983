import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Typography,
  createClasses,
  Colors,
  TextLink,
} from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import noMatchesIcon from '../../../images/no-matches-big.svg';
import { FormattedDate } from '../../../components/FormattedDate';

const classes = createClasses({
  content: {
    padding: '0',
    width: '100%',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    bottom: 0,
  },
  text: {
    display: 'block',
    width: '180px',
    marginTop: '12px',
    color: Colors.Neutral.textWeak,
  },
});

export type Rule = {
  id: string;
  name?: string | null;
  createdAt?: string | null;
};
export type RuleTableProps = {
  loading: boolean;
  className?: string;
  data: Rule[];
  onDetails: (id: string) => void;
};
export const RuleTable: React.FC<RuleTableProps> = ({
  loading,
  className,
  data,
  onDetails,
}) => {
  const { t } = useTranslation();

  const handleDetails = (id: string) => () => {
    onDetails(id);
  };

  return (
    <Table className={className}>
      {((!loading && data.length > 0) || loading) && (
        <TableHead>
          <TableRow>
            <TableHeadCell>{t('rules.rulesTable.headers.name')}</TableHeadCell>
            <TableHeadCell>
              {t('rules.rulesTable.headers.createdAt')}
            </TableHeadCell>
          </TableRow>
        </TableHead>
      )}
      <TableBody>
        {loading && (
          <>
            <TableRow loading>
              <TableCell loading />
              <TableCell loading />
            </TableRow>
            <TableRow loading>
              <TableCell loading />
              <TableCell loading />
            </TableRow>
            <TableRow loading>
              <TableCell loading />
              <TableCell loading />
            </TableRow>
          </>
        )}
        {!loading &&
          data.length > 0 &&
          data.map((row) => (
            <TableRow key={row.id}>
              <TableCell>
                <TextLink component="a" onClick={handleDetails(row.id)}>
                  {row.name}
                </TextLink>
              </TableCell>
              <TableCell>
                <FormattedDate value={row.createdAt} />
              </TableCell>
            </TableRow>
          ))}
        {!loading && data.length === 0 && (
          <TableRow>
            <TableCell>
              <div className={classes.content}>
                <img alt="no-results" src={noMatchesIcon} />
                <Typography variant="labelSmall" className={classes.text}>
                  {t('rules.noRules')}
                </Typography>
              </div>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
