import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ApiError } from '@kp/rest-api-javascript-sdk';
import { useNavigate, useParams } from 'react-router';
import {
  deleteSimulatedDevice,
  getSimulatedGateway,
  updateSimulatedDevice,
} from '../../../api/simulator';
import {
  DeviceEditData,
  SimulatorGatewayDevices,
} from './SimulatorGatewayDevices';
import { useHeader } from '../../../contexts/header-context';
import { useNotifications } from '../../../contexts/notifications-context';
import { useGetGatewayWithChildrenByIdQuery } from '../../../__generated__/types';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';

export const SimulatorGatewayDevicesContainer: React.FC = () => {
  const { simulatedGatewayId = '0' } = useParams();
  const { t } = useTranslation();
  const { add } = useNotifications();
  const navigate = useNavigate();

  const { setTitle } = useHeader();
  useEffect(() => {
    setTitle('');
  }, [setTitle, t]);

  const {
    refetch: callGetSimulatedGateway,
    isLoading: loadingGetSimulatedGateway,
    error: errorGetSimulatedGateway,
    data: responseSimulatedGateway,
  } = useQuery({
    queryKey: ['getSimulatedGateway', simulatedGatewayId],
    queryFn: () => getSimulatedGateway(simulatedGatewayId),
    onError: (err: ApiError) => err,
  });
  const gatewayId = responseSimulatedGateway?.data?.gatewayId;

  const {
    data: gatewayData,
    loading: loadingGateway,
    error: errorGateway,
  } = useGetGatewayWithChildrenByIdQuery({
    variables: { deviceId: gatewayId || '0' },
    skip: !gatewayId,
  });

  const gateway = gatewayData?.device;
  const gatewayName = gateway?.name;
  useBreadcrumb([
    {
      title: t('simulator.breadcrumb.gateways'),
      location: `/simulator`,
    },
    {
      title: loadingGateway
        ? '...'
        : gatewayName || t('simulator.deleted.gateway'),
      location: `/simulator/${simulatedGatewayId}`,
      selected: true,
    },
  ]);

  const { mutate: callEditSimulatedDevice, error: errorEditSimulatedDevice } =
    useMutation({
      mutationFn: updateSimulatedDevice,
      onSuccess: () => callGetSimulatedGateway(),
      onError: (err: ApiError) => err,
    });
  const {
    mutate: callDeleteSimulatedDevice,
    error: errorDeleteSimulatedDevice,
  } = useMutation({
    mutationFn: deleteSimulatedDevice,
    onSuccess: () => callGetSimulatedGateway(),
    onError: (err: ApiError) => err,
  });

  const devices = useMemo(() => {
    const simulatedGateway = responseSimulatedGateway?.data;
    const gatewayChildren = gateway?.inverseParentDevice || [];
    const gatewayAndChildren = [gateway, ...gatewayChildren];

    const simulatedDevices = simulatedGateway?.devices || [];

    const enrichedSimulatedDevices = simulatedDevices.map((simulatedDevice) => {
      const platformDevice = gatewayAndChildren?.find(
        (device) =>
          device?.deviceIdentifier === simulatedDevice.deviceIdentifier,
      );
      const deviceModelCapability =
        platformDevice?.deviceModel.deviceModelCapabilities.find(
          (dmc) => dmc.fieldSelector === simulatedDevice.fieldSelector,
        );
      return {
        ...simulatedDevice,
        id: platformDevice?.id,
        simulatedDeviceId: simulatedDevice.id,
        deviceName: platformDevice?.name || t('simulator.deleted.device') || '',
        capabilityName:
          deviceModelCapability?.capability?.name ||
          t('simulator.deleted.capability') ||
          '',
        gatewayName:
          platformDevice?.deviceIdentifier === gateway?.deviceIdentifier
            ? '-'
            : gateway?.name || t('simulator.deleted.gateway') || '',
      };
    });
    enrichedSimulatedDevices
      .sort((a, b) => a.capabilityName.localeCompare(b.capabilityName))
      .sort((a, b) => a.deviceName.localeCompare(b.deviceName))
      .sort((a, b) => a.gatewayName.localeCompare(b.gatewayName));

    return enrichedSimulatedDevices;
  }, [gateway, responseSimulatedGateway?.data, t]);

  const handleAddDevice = () => {
    navigate('new');
  };
  const handleDeleteDevice = (simulatedDeviceId: string) => {
    callDeleteSimulatedDevice({ simulatedGatewayId, simulatedDeviceId });
  };
  const handleEditDevice = (
    simulatedDeviceId: string,
    data: DeviceEditData,
  ) => {
    callEditSimulatedDevice({
      simulatedGatewayId,
      simulatedDeviceId,
      data,
    });
  };

  useEffect(() => {
    const error =
      errorGetSimulatedGateway ||
      errorGateway ||
      errorEditSimulatedDevice ||
      errorDeleteSimulatedDevice;
    const message =
      error && 'body' in error ? error?.body?.message : error?.message;
    if (message) {
      add({
        type: 'danger',
        id: message,
        content: message,
      });
    }
  }, [
    errorGetSimulatedGateway,
    errorGateway,
    errorEditSimulatedDevice,
    errorDeleteSimulatedDevice,
    add,
  ]);

  return (
    <>
      <SimulatorGatewayDevices
        loading={loadingGetSimulatedGateway || loadingGateway}
        gateway={gatewayData?.device}
        devices={devices || []}
        onAddDevice={gateway ? handleAddDevice : undefined}
        onEditDevice={handleEditDevice}
        onDeleteDevice={handleDeleteDevice}
      />
    </>
  );
};
