declare global {
  interface Window {
    // Applications
    ENV_ADMIN_APP_URI: string;
    ENV_ACCOUNT_APP_URI: string;
    ENV_BUILDING_INSIGHTS_APP_URI: string;
    // Backend end-points
    ENV_AUTH_URI: string;
    ENV_REST_URI: string;
    ENV_GRAPHQL_URI: string;
    // Misc
    ENV_APPLICATIONINSIGHTS_CONNECTION_STRING: string;
  }
}

// Applications
export const ADMIN_APP_URI = window.ENV_ADMIN_APP_URI;
export const ACCOUNT_APP_URI = window.ENV_ACCOUNT_APP_URI;
export const BUILDING_INSIGHTS_APP_URI = window.ENV_BUILDING_INSIGHTS_APP_URI;

// Backend end-points
export const AUTH_URI = window.ENV_AUTH_URI;
export const REST_URI = window.ENV_REST_URI;
export const GRAPHQL_URI = window.ENV_GRAPHQL_URI;

// Misc
export const APPLICATIONINSIGHTS_CONNECTION_STRING =
  window.ENV_APPLICATIONINSIGHTS_CONNECTION_STRING;

// same for all environments
export const CLIENT_ID = 'kxp-developer-app';
