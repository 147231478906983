import * as React from 'react';
import { createClasses, Input, InputProps } from '@kp/react-ui';
import { Controller, useFormContext } from 'react-hook-form';
import classNames from 'classnames';

const classes = createClasses({
  field: {
    minHeight: '70px',
  },
});

export type TextFieldProps = InputProps & {
  name: string;
};

export const TextField: React.FC<TextFieldProps> = (props) => {
  const { control } = useFormContext();
  const { name, label, className, type = 'text' } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <Input
            id={name}
            fullWidth
            {...field}
            {...props}
            type={type}
            className={classNames(className, classes.field)}
            label={label}
            error={error ? error.message : undefined}
          />
        );
      }}
    />
  );
};

TextField.displayName = 'TextField';
