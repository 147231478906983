import {
  UserSvcUserSetting,
  UserSvcUsersService as UserService,
} from '@kp/rest-api-javascript-sdk';

/**
 * Available languages
 */
export enum Language {
  en = 'en',
  de = 'de',
}

export type UserSettings = UserSvcUserSetting;

/**
 * Get user settings
 */
export const getUserSettings = UserService.getUserApiUsersMeSettings;
