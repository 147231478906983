import React from 'react';
import { Button, createClasses, Modal } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';

const classes = createClasses({
  root: { margin: 24, whiteSpace: 'pre-line' },
  buttonContainer: {
    display: 'flex',
    marginTop: 24,
    justifyContent: 'flex-end',
  },
  button: {
    marginLeft: 12,
  },
});

type ConfirmModalProps = {
  open: boolean;
  title: string;
  message?: string | null;
  onConfirm: () => void;
  onCancel: () => void;
};
export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  open,
  title,
  message,
  onConfirm,
  onCancel,
}) => {
  const { t } = useTranslation();
  return (
    <Modal open={open} title={title}>
      <div className={classes.root}>
        {message}
        <div className={classes.buttonContainer}>
          <Button
            className={classes.button}
            size="default"
            variant="primary"
            onClick={onConfirm}
          >
            {t('general.buttons.ok')}
          </Button>
          <Button
            className={classes.button}
            size="default"
            variant="secondary"
            onClick={onCancel}
          >
            {t('general.buttons.discard')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
