import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ApiError } from '@kp/rest-api-javascript-sdk';
import { useNavigate } from 'react-router';
import {
  createSimulatedGateway,
  deleteSimulatedGateway,
  getSimulatedGateways,
  updateSimulatedGateway,
} from '../../../api/simulator';
import { SimulatorGatewayList } from './SimulatorGatewayList';
import { useHeader } from '../../../contexts/header-context';
import {
  useAllGatewaysQuery,
  useRegenerateSharedAccessSignatureMutation,
} from '../../../__generated__/types';
import { useNotifications } from '../../../contexts/notifications-context';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';

// The default expiry time for a shared access token for gateways
// 0.5 years in seconds =  0.5 * 365 * 24 * 60 * 60
export const GATEWAY_ACCESS_SIGNATURE_EXPIRY_SECONDS = 15768000;

export const SimulatorGatewayListContainer: React.FC = () => {
  const { t } = useTranslation();
  const { add } = useNotifications();
  const navigate = useNavigate();

  const { setTitle } = useHeader();
  useEffect(() => {
    setTitle('');
  }, [setTitle, t]);

  useBreadcrumb([
    {
      title: t('simulator.breadcrumb.gateways'),
      location: `/simulator`,
      selected: true,
    },
  ]);

  const {
    refetch: callGetSimulatedGateways,
    isLoading: loadingGetSimulatedGateways,
    error: errorGetSimulatedGateways,
    data: allSimulatedGateways,
  } = useQuery({
    queryKey: ['getSimulatedGateways'],
    queryFn: getSimulatedGateways,
    onError: (err: ApiError) => err,
  });
  const {
    loading: loadingGetAllGateways,
    error: errorGetAllGateways,
    data: allGateways,
  } = useAllGatewaysQuery();

  const {
    mutate: callCreateSimulatedGateway,
    error: errorCreateSimulatedGateway,
  } = useMutation({
    mutationFn: createSimulatedGateway,
    onSuccess: () => callGetSimulatedGateways(),
    onError: (err: ApiError) => err,
  });
  const {
    mutate: callEditSimulatedGateway,
    isLoading: loadingEditSimulatedGateway,
    error: errorEditSimulatedGateway,
  } = useMutation({
    mutationFn: updateSimulatedGateway,
    onSuccess: () => callGetSimulatedGateways(),
    onError: (err: ApiError) => err,
  });
  const {
    mutate: callDeleteSimulatedGateway,
    error: errorDeleteSimulatedGateway,
  } = useMutation({
    mutationFn: deleteSimulatedGateway,
    onSuccess: () => callGetSimulatedGateways(),
    onError: (err: ApiError) => err,
  });
  const [
    callRegenerateSharedAccessSignature,
    { error: errorGenerateAccessSignature },
  ] = useRegenerateSharedAccessSignatureMutation();

  const orphanedSimulatedGateways = useMemo(
    () =>
      (allSimulatedGateways?.data || [])
        .map((simulatedGateway) => {
          const gateway = allGateways?.devices?.items?.find(
            (gw) => gw.id === simulatedGateway.gatewayId,
          );
          return {
            ...gateway,
            id: simulatedGateway.gatewayId,
            simulatedGatewayId: simulatedGateway.id,
          };
        })
        .filter((gateway) => !gateway.name)
        .map((gateway) => ({
          ...gateway,
          name: t('simulator.deleted.gateway'),
        })),
    [allGateways, allSimulatedGateways, t],
  );

  const gateways = useMemo(
    () =>
      (allGateways?.devices?.items || []).map((gateway) => {
        const simulatedGateway = allSimulatedGateways?.data?.find(
          (simulatedDevice) => gateway.id === simulatedDevice.gatewayId,
        );
        return {
          ...gateway,
          simulatedGatewayId: simulatedGateway?.id,
          isInactive: simulatedGateway?.isInactive,
        };
      }),
    [allGateways, allSimulatedGateways],
  );

  const handleCreate = async (gatewayId: string, simulate: boolean) => {
    if (simulate) {
      return callRegenerateSharedAccessSignature({
        variables: {
          gatewayId,
          expirySeconds: GATEWAY_ACCESS_SIGNATURE_EXPIRY_SECONDS,
        },
      })
        .then((result) => {
          const sharedAccessKey =
            result.data?.regenerateGatewayDeviceSharedAccessSignature
              .sharedAccessSignature;
          if (!sharedAccessKey) return null;
          return callCreateSimulatedGateway({ gatewayId, sharedAccessKey });
        })
        .catch(console.warn);
    }

    const simulatedGateway = allSimulatedGateways?.data?.find(
      (gw) => gw.gatewayId === gatewayId,
    );
    if (!simulatedGateway?.id) return null;
    return callDeleteSimulatedGateway(simulatedGateway.id);
  };

  const handleEdit = async (simulatedGatewayId: string, activate: boolean) => {
    callEditSimulatedGateway({
      simulatedGatewayId,
      data: { isInactive: !activate },
    });
  };

  const handleNavigateDetails = (id: string) => {
    navigate(id);
  };

  useEffect(() => {
    const error =
      errorGetAllGateways ||
      errorGetSimulatedGateways ||
      errorCreateSimulatedGateway ||
      errorDeleteSimulatedGateway ||
      errorGenerateAccessSignature ||
      errorEditSimulatedGateway;
    const message =
      error && 'body' in error ? error?.body?.message : error?.message;
    if (message) {
      add({
        type: 'danger',
        id: message,
        content: message,
      });
    }
  }, [
    errorGetAllGateways,
    errorGetSimulatedGateways,
    errorCreateSimulatedGateway,
    errorDeleteSimulatedGateway,
    errorGenerateAccessSignature,
    errorEditSimulatedGateway,
    add,
  ]);

  return (
    <SimulatorGatewayList
      loading={loadingGetAllGateways || loadingGetSimulatedGateways}
      activating={loadingEditSimulatedGateway}
      gateways={gateways}
      orphanedSimulatedGateways={orphanedSimulatedGateways}
      onSimulate={handleCreate}
      onActivate={handleEdit}
      onDetails={handleNavigateDetails}
    />
  );
};
