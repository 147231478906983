import {
  Breadcrumb,
  BreadcrumbLink,
  BreadcrumbRootLink,
  Colors,
  createClasses,
  Icons,
} from '@kp/react-ui';
import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useBreadcrumb } from '../../contexts/breadcrumb-context';

const classes = createClasses({
  root: {
    padding: '10px 24px',
    background: Colors.Neutral.background,
  },
});

export const Breadcrumbs: React.FC = () => {
  const navigate = useNavigate();
  const links = useBreadcrumb();

  return (
    <Breadcrumb className={classes.root}>
      <BreadcrumbRootLink
        icon={Icons.IconSimulator}
        component={NavLink}
        to="/"
      />
      {links.map((link, index) => {
        return !link.selected ? (
          <BreadcrumbLink
            key={`${index}-${link.location}`}
            title={link.title}
            onClick={
              link.onClick ||
              (() => {
                navigate(link.location);
              })
            }
          />
        ) : (
          <BreadcrumbLink
            selected={link.selected}
            key={`${index}-${link.location}`}
            title={link.title}
          />
        );
      })}
    </Breadcrumb>
  );
};
