import { ToastProps } from '@kp/react-ui';
import React, {
  useContext,
  createContext,
  useState,
  useMemo,
  useCallback,
} from 'react';

interface Notification {
  id: string;
  group?: string;
  type: ToastProps['variant'];
  content: React.ReactNode;
  dismissTimeout?: number;
}

interface NotificationsContextValue {
  notifications: Notification[];
  add: (notification: Notification) => void;
  dismiss: (id: string) => void;
  dismissGroup: (group: string) => void;
}

const NotificationsContext = createContext<NotificationsContextValue>({
  notifications: [],
  add: () => {},
  dismiss: () => {},
  dismissGroup: () => {},
});

export const useNotifications = (): NotificationsContextValue => {
  const values = useContext(NotificationsContext);
  if (!values.notifications) {
    throw new Error(
      `useNotifications must be used within a NotificationsProvider`,
    );
  }
  return values;
};

interface NotificationsProviderProps {
  children?: React.ReactNode;
}

export const NotificationsProvider: React.FC<NotificationsProviderProps> = ({
  children,
}) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const add = useCallback(
    (notification: Notification) => {
      setNotifications((state) =>
        state.find(({ id }) => id === notification.id)
          ? state
          : state.concat(notification),
      );
    },
    [setNotifications],
  );
  const dismiss = useCallback(
    (id: string) => {
      setNotifications((state) =>
        state.find((notification) => notification.id === id)
          ? state.filter((notification) => notification.id !== id)
          : state,
      );
    },
    [setNotifications],
  );
  const dismissGroup = useCallback(
    (group: string) => {
      setNotifications((state) =>
        state.filter((notification) => notification.group !== group),
      );
    },
    [setNotifications],
  );
  const value = useMemo(
    () => ({
      add,
      dismiss,
      dismissGroup,
      notifications,
    }),
    [notifications, add, dismiss, dismissGroup],
  );
  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  );
};
