import React from 'react';
import { createClasses } from '@kp/react-ui';

const classes = createClasses({
  root: {
    display: 'flex',
    maxWidth: '100vw',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    zIndex: '1000',
  },
});

interface GenericPageLayoutProps {
  children?: React.ReactNode;
}

export const GenericPageLayout: React.FC<GenericPageLayoutProps> = ({
  children,
}) => <div className={classes.root}>{children}</div>;
