import React, { useEffect } from 'react';
import { useGenericPages } from '../contexts/generic-page-context';

export const NotFoundHandler: React.FC = () => {
  const { activateNotFound } = useGenericPages();

  useEffect(() => activateNotFound(), [activateNotFound]);

  return <></>;
};
