import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ProtectedRoute } from '@kp/react-sdk';
import { RuleListContainer } from './list/RuleListContainer';
import { NotFoundHandler } from '../NotFoundHandler';

export const RuleRoutes: React.FC = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<ProtectedRoute element={<RuleListContainer />} />}
      />
      <Route path="*" element={<NotFoundHandler />} />
    </Routes>
  );
};
