import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@kp/react-sdk';
import { useQuery } from '@tanstack/react-query';
import { getUserSettings } from '../api/user';

interface Settings {
  language?: string;
}

type SettingsProviderProps = {
  children: React.ReactNode;
};
export const SettingsProvider: React.FC<SettingsProviderProps> = ({
  children,
}) => {
  const [settings, setSettings] = useState<Settings>({});
  const { isAuthenticated } = useAuth();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (settings?.language) {
      i18n.changeLanguage(settings.language);
    }
  }, [settings, i18n]);

  const { refetch } = useQuery({
    queryKey: ['getUserSettings'],
    queryFn: getUserSettings,
    enabled: isAuthenticated,
    onSuccess: (response) => setSettings(response?.data || {}),
    onError: () => setSettings({}),
  });
  useEffect(() => {
    refetch();
  }, [isAuthenticated, refetch]);

  return <>{children}</>;
};
