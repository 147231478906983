import React, { useState } from 'react';
import { Toast, Icons, ToastProps } from '@kp/react-ui';
import { useTimeoutWhen } from 'rooks';

export interface NotificationProps {
  /** Pass a CSS class name. */
  className?: string;
  /** Set the type of the notification. */
  type: ToastProps['variant'];
  /** The content of the notification. */
  children: React.ReactNode;
  /** Called when the notification is dismissed. */
  onDismiss?: () => void;
  /** Automatically closes notification after given interval */
  dismissTimeout?: number;
  noIcon?: boolean;
}

export const Notification: React.FC<NotificationProps> = ({
  className,
  children,
  type,
  onDismiss,
  dismissTimeout,
}) => {
  const [show, setShow] = useState(true);

  useTimeoutWhen(() => setShow(false), dismissTimeout, !!dismissTimeout);

  return (
    <div className={className} data-testid={'notification'}>
      {show && (
        <Toast
          onDismiss={dismissTimeout ? undefined : onDismiss}
          icon={type === 'danger' ? undefined : Icons.IconCheck}
          variant={type}
        >
          {children}
        </Toast>
      )}
    </div>
  );
};
