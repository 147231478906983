import React, { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ApiError } from '@kp/rest-api-javascript-sdk';
import { useTranslation } from 'react-i18next';
import {
  createSimulatedDevice,
  getSimulatedGateway,
} from '../../../api/simulator';
import {
  DeviceCreateData,
  SimulatorDeviceCreate,
} from './SimulatorDeviceCreate';
import { useGetGatewayWithChildrenByIdQuery } from '../../../__generated__/types';
import { useNotifications } from '../../../contexts/notifications-context';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { useHeader } from '../../../contexts/header-context';

export const SimulatorDeviceCreateContainer: React.FC = () => {
  const navigate = useNavigate();
  const { simulatedGatewayId = '0' } = useParams();
  const { add } = useNotifications();
  const { t } = useTranslation();

  const { setTitle } = useHeader();

  useEffect(() => {
    setTitle('');
  }, [setTitle, t]);

  const {
    isLoading: loadingGetSimulatedGateway,
    error: errorGetSimulatedGateway,
    data: responseSimulatedGateway,
  } = useQuery({
    queryKey: ['getSimulatedGateway', simulatedGatewayId],
    queryFn: () => getSimulatedGateway(simulatedGatewayId),
    onError: (err: ApiError) => err,
  });
  const gatewayId = responseSimulatedGateway?.data?.gatewayId;

  const {
    data: gatewayData,
    loading: loadingGateway,
    error: errorGateway,
  } = useGetGatewayWithChildrenByIdQuery({
    variables: { deviceId: gatewayId || '0' },
    skip: !gatewayId,
  });

  const gatewayName = gatewayData?.device?.name;
  useBreadcrumb([
    {
      title: t('simulator.breadcrumb.gateways'),
      location: `/simulator`,
    },
    {
      title: loadingGateway
        ? '...'
        : gatewayName || t('simulator.deleted.gateway'),
      location: `/simulator/${simulatedGatewayId}`,
    },
    {
      title: t('simulator.breadcrumb.create'),
      location: `/simulator/${simulatedGatewayId}/new`,
      selected: true,
    },
  ]);

  const allDevices = useMemo(() => {
    const gateway = gatewayData?.device;
    const children = gatewayData?.device?.inverseParentDevice;
    const gatewayAndChildren = [
      ...(gateway ? [gateway] : []),
      ...(children || []),
    ];
    return gatewayAndChildren.map((device) => ({
      id: device.id,
      name: device.name,
      deviceIdentifier: device.deviceIdentifier,
      deviceIdentifierFieldSelector:
        gateway?.deviceModel.deviceIdentifierFieldSelector ||
        device.deviceModel.deviceIdentifierFieldSelector,
      capabilities: device.deviceModel.deviceModelCapabilities.map((dmc) => ({
        id: dmc.id,
        name: dmc.capability.name,
        fieldSelector: dmc.fieldSelector,
      })),
    }));
  }, [gatewayData?.device]);

  const {
    mutate: create,
    error: errorAddDevice,
    isLoading: loadingAddDevice,
  } = useMutation({
    mutationFn: createSimulatedDevice,
    onSuccess: () => navigate(`/simulator/${simulatedGatewayId}`),
    onError: (err: ApiError) => err,
  });

  const handleSubmit = (input: DeviceCreateData) => {
    const deviceToSimulate = allDevices.find(
      (device) => device.id === input.deviceId,
    );
    const capabilityToSimulate = deviceToSimulate?.capabilities.find(
      (capability) => capability.id === input.deviceModelCapabilityId,
    );

    const { deviceIdentifier, deviceIdentifierFieldSelector } =
      deviceToSimulate || {};
    const { fieldSelector } = capabilityToSimulate || {};

    if (!deviceIdentifier || !deviceIdentifierFieldSelector || !fieldSelector) {
      console.warn('Device creation failed because one was undefined:', {
        deviceIdentifier,
        deviceIdentifierFieldSelector,
        fieldSelector,
      });
      return;
    }

    const data = {
      ...input,
      deviceIdentifier,
      deviceIdentifierFieldSelector,
      fieldSelector,
    };

    create({ simulatedGatewayId, data });
  };
  const handleDiscard = () => {
    navigate(`/simulator/${simulatedGatewayId}`);
  };

  useEffect(() => {
    const error = errorGateway || errorAddDevice || errorGetSimulatedGateway;
    const message =
      error && 'body' in error ? error?.body?.message : error?.message;
    if (message) {
      add({
        type: 'danger',
        id: message,
        content: message,
      });
    }
  }, [errorGateway, errorAddDevice, errorGetSimulatedGateway, add]);

  return (
    <SimulatorDeviceCreate
      loading={loadingGateway || loadingAddDevice || loadingGetSimulatedGateway}
      allDevices={allDevices}
      onSubmit={handleSubmit}
      onDiscard={handleDiscard}
    />
  );
};
