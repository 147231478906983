import React, { useMemo } from 'react';
import GraphiQL from 'graphiql';
import { createGraphiQLFetcher } from '@graphiql/toolkit';
import { createClasses } from '@kp/react-ui';
import { useAuth } from '@kp/react-sdk';
import { GRAPHQL_URI } from '../../utils/env';
import 'graphiql/graphiql.min.css';
import './playground.css';

const subscriptionUrl = `${GRAPHQL_URI.replace('http', 'ws')}${
  GRAPHQL_URI.endsWith('/') ? '' : '/'
}subscription/`;

const classes = createClasses({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  playground: {
    height: '100%',
    fontSize: 14,
    lineHeight: 'normal',
    letterSpacing: 'normal',
    '& *, & *:before, & *:after': {
      boxSizing: 'content-box',
    },
  },
});

export const Playground: React.FC = () => {
  const { user } = useAuth();

  // graphiql defaults to the system theme, if that is dark, the ui does not match ours
  // so we default to light, unless the user changes it
  const currentTheme = localStorage.getItem('graphiql:theme');
  if (!currentTheme) localStorage.setItem('graphiql:theme', 'light');

  const authorization = user?.access_token
    ? `Bearer ${user?.access_token}`
    : '';

  const fetcher = useMemo(
    () =>
      createGraphiQLFetcher({
        url: GRAPHQL_URI,
        subscriptionUrl,
        wsConnectionParams: { authorization },
        headers: {
          authorization,
        },
      }),
    [authorization],
  );

  return (
    <div className={classes.root}>
      <div className={classes.playground}>
        <GraphiQL fetcher={fetcher} />
      </div>
    </div>
  );
};
