import React, { useCallback } from 'react';
import {
  TableRow,
  TableCell,
  createClasses,
  Dropdown,
  Input,
  Button,
  Icons,
  ListItem,
} from '@kp/react-ui';
import urljoin from 'url-join';
import { Algorithms } from '../../../api/simulator';
import { ADMIN_APP_URI } from '../../../utils/env';

const classes = createClasses({
  inputField: {
    textAlign: 'right',
  },
});

export type DeviceEditData = {
  algorithm?: Algorithms;
  value?: number;
  sendInterval?: number;
};

export type Device = {
  id?: string;
  simulatedDeviceId: string;
  deviceName?: string;
  capabilityName?: string;
  gatewayName?: string;
  algorithm: Algorithms;
  value?: number;
  sendInterval: number;
};

export type DeviceTableRowProps = {
  id?: string;
  simulatedDeviceId: string;
  deviceName?: string;
  capabilityName?: string;
  gatewayName?: string;
  algorithm: Algorithms;
  value?: number;
  sendInterval: number;
  onEdit: (id: string, data: DeviceEditData) => void;
  onDelete?: (simulatedDeviceId: string) => void;
};

const algorithms = ['fixed', 'sinus', 'sawtooth', 'rectangle', 'triangle'];

export const DeviceTableRow: React.FC<DeviceTableRowProps> = ({
  id,
  simulatedDeviceId,
  deviceName,
  capabilityName,
  gatewayName,
  algorithm,
  value,
  sendInterval,
  onEdit,
  onDelete,
}) => {
  const handleChangeAlgorithm = useCallback(
    (input: string) =>
      onEdit(simulatedDeviceId, { algorithm: input as Algorithms }),
    [onEdit, simulatedDeviceId],
  );
  const handleChangeValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      onEdit(simulatedDeviceId, { value: Number(event.target.value) }),
    [onEdit, simulatedDeviceId],
  );
  const handleChangeSendInterval = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      onEdit(simulatedDeviceId, {
        sendInterval: Number(event.target.value),
      }),
    [onEdit, simulatedDeviceId],
  );

  return (
    <TableRow>
      <TableCell> {deviceName}</TableCell>
      <TableCell>{capabilityName}</TableCell>
      <TableCell>{gatewayName}</TableCell>
      <TableCell>
        <Dropdown value={algorithm} onChange={handleChangeAlgorithm}>
          {algorithms.map((algo) => (
            <ListItem key={algo} title={algo} value={algo} />
          ))}
        </Dropdown>
      </TableCell>
      <TableCell align="right">
        <Input
          fullWidth
          defaultValue={value || '-'}
          onBlur={handleChangeValue}
          inputClassName={classes.inputField}
          disabled={algorithm !== 'fixed'}
        />
      </TableCell>
      <TableCell align="right">
        <Input
          fullWidth
          defaultValue={sendInterval}
          onBlur={handleChangeSendInterval}
          inputClassName={classes.inputField}
        />
      </TableCell>
      <TableCell align="center">
        {onDelete && (
          <Button
            icon={Icons.IconTrash}
            size="default"
            variant="tertiary"
            onClick={() => onDelete(simulatedDeviceId)}
            data-testid={`button-delete-${simulatedDeviceId}`}
          />
        )}
      </TableCell>
      <TableCell align="center">
        {!!id && (
          <Button
            component="a"
            icon={Icons.IconSearch}
            size="default"
            variant="tertiary"
            href={urljoin(ADMIN_APP_URI, 'devices', id)}
            target="_admin_app"
            data-testid={`button-details-${id}`}
          />
        )}
      </TableCell>
    </TableRow>
  );
};
