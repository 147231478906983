import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  DeviceFormData,
  getDeviceFormInfoSchema,
  SimulatorDeviceForm,
} from './SimulatorDeviceForm';

export type DeviceCreateData = DeviceFormData;

type SimulatorDeviceCreateProps = {
  loading: boolean;
  allDevices: Array<{
    id: string;
    name: string;
    capabilities: Array<{ id: string; name: string }>;
  }>;
  onSubmit: (data: DeviceCreateData) => void;
  onDiscard: () => void;
};
export const SimulatorDeviceCreate: React.FC<SimulatorDeviceCreateProps> = ({
  loading,
  allDevices,
  onSubmit,
  onDiscard,
}) => {
  const { t } = useTranslation();

  const validationSchema = getDeviceFormInfoSchema(t);

  const defaultValues: DeviceCreateData = {
    deviceId: '',
    deviceModelCapabilityId: '-1',
    algorithm: 'fixed',
    sendInterval: 1,
    value: 10,
  };
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, setValue } = methods;

  const handleSelectDevice = (value: string) => {
    setValue('deviceId', value);
    setValue('deviceModelCapabilityId', '0');
  };

  return (
    <FormProvider {...methods}>
      <SimulatorDeviceForm
        loading={loading}
        allDevices={allDevices}
        onSelectDevice={handleSelectDevice}
        onDiscard={onDiscard}
        onSubmit={handleSubmit(onSubmit)}
      />
    </FormProvider>
  );
};
