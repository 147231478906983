import React from 'react';
import { createClasses } from '@kp/react-ui';
import { DocsMarkdown } from './DocsMarkdown';

const classes = createClasses({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '800px',
    margin: '32px',
  },
});

interface DocsProps {
  pages: Array<{ label: string; path: string }>;
  content?: string;
}

export const Docs: React.FC<DocsProps> = ({ content }) => {
  return (
    <div className={classes.root}>
      <DocsMarkdown content={content || ''} />
    </div>
  );
};
