import React from 'react';
import { Dropdown, DropdownProps } from '@kp/react-ui';
import { Controller, useFormContext } from 'react-hook-form';

export type DropdownFieldProps = Omit<DropdownProps, 'value' | 'form'> & {
  name: string;
};

export const DropdownField: React.FC<DropdownFieldProps> = (props) => {
  const { control } = useFormContext();
  const { name, label, className } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <Dropdown
            id={name}
            {...field}
            {...props}
            className={className}
            label={label}
            error={error ? error.message : undefined}
          />
        );
      }}
    />
  );
};

DropdownField.displayName = 'DropdownField';
