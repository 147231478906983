import React from 'react';
import { useLocaleDateFns } from '../../hooks/date-fns';

type FormattedDateProps = {
  pre?: string;
  value?: null | string | Date;
  alt?: string;
  hideSeconds?: boolean;
};
export const FormattedDate: React.FC<FormattedDateProps> = ({
  pre = '',
  value,
  alt,
  hideSeconds,
}) => {
  const { formatDefault } = useLocaleDateFns();

  let formattedDate = alt ?? '';
  let date = new Date();
  if (value) {
    date = typeof value === 'string' ? new Date(value) : value;
    formattedDate = formatDefault(date, hideSeconds);
  }
  return (
    <span data-testid={`date-${date.toISOString()}`}>
      {pre + formattedDate}
    </span>
  );
};
