import React from 'react';
import 'graphql-voyager/dist/voyager.css';
import { createClasses } from '@kp/react-ui';
import classNames from 'classnames';
import { useSidebar } from '../../contexts/sidebar-context';
import { SIDEBAR_CLOSED_WIDTH, SIDEBAR_OPENED_WIDTH } from '../../constants/UI';
import { CachedAppVoyager } from './CachedAppVoyager';

const classes = createClasses({
  voyager: {
    height: '100%',
    overflow: 'hidden',
    fontSize: 14,
    lineHeight: 'normal',
    letterSpacing: 'normal',
    '& *, & *:before, & *:after': {
      boxSizing: 'content-box',
    },
    '& .graphql-voyager .type-doc .loading': {
      display: 'none',
    },
  },
  voyagerOpen: {
    '& .graphql-voyager > .menu-content': {
      transition: 'transform 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
      transform: `translateX(${SIDEBAR_OPENED_WIDTH}px)`,
    },
    '& .graphql-voyager > .loading-box': {
      left: 320 + SIDEBAR_OPENED_WIDTH,
    },
  },
  voyagerClosed: {
    '& .graphql-voyager > .menu-content': {
      transition: 'transform 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
      transform: `translateX(${SIDEBAR_CLOSED_WIDTH}px)`,
    },
    '& .graphql-voyager > .loading-box': {
      left: 320 + SIDEBAR_CLOSED_WIDTH,
    },
  },
});

export const Voyager: React.FC = () => {
  const { open } = useSidebar();
  return (
    <div
      className={classNames(
        classes.voyager,
        open ? classes.voyagerOpen : classes.voyagerClosed,
      )}
    >
      <CachedAppVoyager />
    </div>
  );
};
