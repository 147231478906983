import { useMemo, useCallback } from 'react';
import { format as formatDateFns, parse as parseDateFns } from 'date-fns';
import { de, enUS } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';

const getLocale = (language: string) => {
  switch (language) {
    case 'en':
      return enUS;
    case 'de':
      return de;
    default:
      return enUS;
  }
};

export const useLocaleDateFns: () => {
  formatDefaultStr: (date: string, hideSeconds?: boolean) => string;
  formatDefault: (date: Date | number, hideSeconds?: boolean) => string;
  format: typeof formatDateFns;
  parse: typeof parseDateFns;
} = () => {
  const { i18n } = useTranslation();

  const locale = useMemo(() => getLocale(i18n.language), [i18n.language]);

  const format: typeof formatDateFns = useCallback(
    (date, fmt, options) =>
      formatDateFns(date, fmt, {
        locale,
        ...options,
      }),
    [locale],
  );
  const formatDefault = (date: Date | number, hideSeconds: boolean = false) =>
    format(date, hideSeconds ? 'P p' : 'P pp');
  const formatDefaultStr = (date: string, hideSeconds: boolean = false) =>
    format(new Date(date), hideSeconds ? 'P p' : 'P pp');

  const parse: typeof parseDateFns = useCallback(
    (dateString, formatString, referenceDate, options) =>
      parseDateFns(dateString, formatString, referenceDate, {
        locale,
        ...options,
      }),
    [locale],
  );

  return { formatDefaultStr, formatDefault, format, parse };
};
