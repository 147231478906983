import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { ProtectedRoute } from '@kp/react-sdk';
import { Layout } from './components/Layout';
import { NotFoundHandler } from './features/NotFoundHandler';
import { Playground } from './features/playground/Playground';
import { Voyager } from './features/voyager/Voyager';
import { DocsContainer } from './features/docs/DocsContainer';
import { Swagger } from './features/swagger/Swagger';
import { SimulatorRoutes } from './features/simulator/SimulatorRoutes';
import { RuleRoutes } from './features/rules/RuleRoutes';

const defaultLayout: string[] = [
  '/voyager',
  '/swagger',
  '/docs',
  '/playground',
  '/simulator',
  '/rules',
];
const plainLayout: string[] = ['/voyager', '/swagger', '/docs', '/playground'];

const App: React.FC = () => {
  const location = useLocation();

  const isDefaultLayout = defaultLayout.some((route) =>
    location.pathname.startsWith(route),
  );

  const isPlain = plainLayout.some((route) =>
    location.pathname.startsWith(route),
  );
  return (
    <Layout simple={!isDefaultLayout} plain={isPlain}>
      <Routes>
        <Route
          path="/"
          element={<Navigate to="docs/authentication" replace />}
        />
        <Route
          path="/playground"
          element={<ProtectedRoute element={<Playground />} />}
        />
        <Route
          path="/voyager"
          element={<ProtectedRoute element={<Voyager />} />}
        />
        <Route
          path="/docs/:page"
          element={<ProtectedRoute element={<DocsContainer />} />}
        />
        <Route
          path="/docs"
          element={<ProtectedRoute element={<DocsContainer />} />}
        />
        <Route
          path="/swagger"
          element={<ProtectedRoute element={<Swagger />} />}
        />
        <Route
          path="/simulator/*"
          element={<ProtectedRoute element={<SimulatorRoutes />} />}
        />
        <Route
          path="/rules/*"
          element={<ProtectedRoute element={<RuleRoutes />} />}
        />
        <Route path="*" element={<NotFoundHandler />} />
      </Routes>
    </Layout>
  );
};

export default App;
