import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom';
import {
  Icons,
  Sidebar as Component,
  SidebarItem,
  SIDEBAR_ITEM_ACTIVE_CLASS_NAME,
  SidebarSubNavigation,
  SIDEBAR_SUB_ITEM_ACTIVE_CLASS_NAME,
  createClasses,
} from '@kp/react-ui';
import classNames from 'classnames';
import { docs } from '../../__generated__/docs';

const classes = createClasses({
  root: {
    // raises the sidebar tooltip above layout content
    zIndex: 99,
  },
});

const SidebarLink = React.forwardRef<
  HTMLAnchorElement,
  Omit<NavLinkProps, 'className' | 'children'> & {
    className?: string;
    children?: React.ReactNode;
    activeClassName?: string;
  }
>(({ className, activeClassName, ...props }, ref) => (
  <NavLink
    ref={ref}
    className={({ isActive }) =>
      classNames(className, isActive && activeClassName)
    }
    {...(props as NavLinkProps)}
  />
));

SidebarLink.displayName = 'SidebarLink';

const pages = docs.map((item) => ({
  label: item?.metadata.name,
  path: `/docs/${item?.metadata.slug}`,
}));

interface SidebarProps {
  isCollapsed?: boolean;
}

export const Sidebar: React.FC<SidebarProps> = ({ isCollapsed }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const getIcon = (path: string) => {
    switch (path) {
      case '/docs/authentication':
        return Icons.IconAuthentication;
      case '/docs/permissions':
        return Icons.IconPermissions;
      case '/docs/jumpstart':
        return Icons.IconGraphql;
    }
    return Icons.IconAsterisk;
  };

  return (
    <Component open={!isCollapsed} className={classes.root}>
      <SidebarItem
        component={SidebarLink}
        to="/docs"
        icon={Icons.IconDocumentation}
        activeClassName={SIDEBAR_ITEM_ACTIVE_CLASS_NAME}
        title={t('docs.page')}
      />
      <SidebarSubNavigation open={location.pathname.startsWith('/docs')}>
        {pages.map((page) => (
          <SidebarItem
            key={page.path}
            icon={getIcon(page.path)}
            title={page.label}
            component={SidebarLink}
            activeClassName={SIDEBAR_SUB_ITEM_ACTIVE_CLASS_NAME}
            selected={location.pathname === page.path}
            to={page.path}
            sub
          />
        ))}
      </SidebarSubNavigation>
      <SidebarItem
        component={SidebarLink}
        to="/playground"
        icon={Icons.IconPlayground}
        activeClassName={SIDEBAR_ITEM_ACTIVE_CLASS_NAME}
        title={t('playground.page')}
      />
      <SidebarItem
        component={SidebarLink}
        to="/voyager"
        icon={Icons.IconVoyager}
        activeClassName={SIDEBAR_ITEM_ACTIVE_CLASS_NAME}
        title={t('voyager.page')}
      />
      <SidebarItem
        component={SidebarLink}
        to="/swagger"
        icon={Icons.IconApi}
        activeClassName={SIDEBAR_ITEM_ACTIVE_CLASS_NAME}
        title={t('swagger.page')}
      />
      <SidebarItem
        component={SidebarLink}
        to="/simulator"
        icon={Icons.IconSimulator}
        activeClassName={SIDEBAR_ITEM_ACTIVE_CLASS_NAME}
        title={t('simulator.page')}
      />
      <SidebarItem
        component={SidebarLink}
        to="/rules"
        icon={Icons.IconCustomer}
        activeClassName={SIDEBAR_ITEM_ACTIVE_CLASS_NAME}
        title={t('rules.page')}
      />
    </Component>
  );
};
