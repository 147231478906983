import React from 'react';
import {
  Button,
  Icons,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Typography,
  createClasses,
  Colors,
  TextLink,
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
} from '@kp/react-ui';
import urljoin from 'url-join';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ADMIN_APP_URI } from '../../../utils/env';
import noMatchesIcon from '../../../images/no-matches-big.svg';
import { ConnectionStates } from '../../../__generated__/types';

const classes = createClasses({
  content: {
    padding: '0',
    width: '100%',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    bottom: 0,
  },
  text: {
    display: 'block',
    width: '180px',
    marginTop: '12px',
    color: Colors.Neutral.textWeak,
  },
  activeColumn: {
    width: 150,
  },
  activeWrapper: {
    display: 'flex',
  },
  simulateColumn: {
    width: 100,
  },
  actionColumn: {
    textAlign: 'center',
    width: 120,
  },
});

export type Gateway = {
  id: string;
  name: string;
  simulatedGatewayId?: string;
  isInactive?: boolean;
  connectionState?: ConnectionStates;
};
export type GatewayTableProps = {
  loading: boolean;
  activating: boolean;
  className?: string;
  data: Gateway[];
  onChangeSimulated?: (id: string) => (value: string) => void;
  onChangeActive?: (id: string) => (value: string) => void;
  onDetails: (id: string) => void;
  onDelete?: (id: string) => void;
};
export const GatewayTable: React.FC<GatewayTableProps> = ({
  loading,
  activating,
  className,
  data,
  onChangeSimulated,
  onChangeActive,
  onDetails,
  onDelete,
}) => {
  const { t } = useTranslation();

  const handleDetails = (id: string) => () => {
    onDetails(id);
  };

  const headers = [
    t('simulator.gatewaysTable.headers.name'),
    onChangeActive ? t('simulator.gatewaysTable.headers.active') : '',
    onChangeSimulated ? t('simulator.gatewaysTable.headers.simulate') : '',
    ...(onDelete
      ? [t('simulator.gatewaysTable.headers.delete')]
      : [t('simulator.gatewaysTable.headers.details')]),
  ];

  return (
    <Table className={className}>
      {((!loading && data.length > 0) || loading) && headers && (
        <TableHead>
          <TableRow>
            {headers.map((header, idx) => (
              <TableHeadCell
                key={header + idx}
                className={classNames(
                  idx === headers.length - 3 && classes.activeColumn,
                  idx === headers.length - 2 && classes.simulateColumn,
                  idx === headers.length - 1 && classes.actionColumn,
                )}
              >
                {header}
              </TableHeadCell>
            ))}
          </TableRow>
        </TableHead>
      )}
      <TableBody>
        {loading && (
          <>
            <TableRow loading>
              <TableCell loading />
              <TableCell loading />
              <TableCell loading />
              <TableCell loading />
            </TableRow>
            <TableRow loading>
              <TableCell loading />
              <TableCell loading />
              <TableCell loading />
              <TableCell loading />
            </TableRow>
            <TableRow loading>
              <TableCell loading />
              <TableCell loading />
              <TableCell loading />
              <TableCell loading />
            </TableRow>
          </>
        )}
        {!loading &&
          data.length > 0 &&
          data.map((row) => (
            <TableRow key={row.id}>
              <TableCell>
                {row.simulatedGatewayId ? (
                  <TextLink
                    component="a"
                    onClick={handleDetails(row.simulatedGatewayId)}
                  >
                    {row.name}
                  </TextLink>
                ) : (
                  row.name
                )}
              </TableCell>
              <TableCell>
                {onChangeActive && row.simulatedGatewayId && (
                  <div className={classes.activeWrapper}>
                    <ToggleButtonGroup
                      value={row.isInactive ? 'inactive' : 'active'}
                      onChange={onChangeActive(row.simulatedGatewayId)}
                      data-testid={`toggle-value-${row.simulatedGatewayId}-${
                        row.isInactive ? 'inactive' : 'active'
                      }`}
                    >
                      <ToggleButton
                        value="active"
                        icon={Icons.IconViewOn}
                        data-testid={`button-activate-${row.simulatedGatewayId}`}
                        disabled={activating}
                      />
                      <ToggleButton
                        value="inactive"
                        icon={Icons.IconViewOff}
                        data-testid={`button-deactivate-${row.simulatedGatewayId}`}
                        disabled={activating}
                      />
                    </ToggleButtonGroup>
                  </div>
                )}
              </TableCell>
              <TableCell>
                {onChangeSimulated && row.simulatedGatewayId && (
                  <IconButton
                    icon={Icons.IconTrash}
                    onClick={() => onChangeSimulated(row.id)('notSimulated')}
                    data-testid={`button-unsimulate-${row.simulatedGatewayId}`}
                  />
                )}
                {onChangeSimulated && !row.simulatedGatewayId && (
                  <IconButton
                    icon={Icons.IconGatewaySensor}
                    onClick={() => onChangeSimulated(row.id)('simulate')}
                    data-testid={`button-simulate-${row.id}`}
                    disabled={
                      row.connectionState === ConnectionStates.Connected
                    }
                  />
                )}
              </TableCell>
              {row.id && (
                <TableCell align="center">
                  {!onDelete && (
                    <Button
                      component="a"
                      icon={Icons.IconSearch}
                      size="default"
                      variant="tertiary"
                      href={urljoin(ADMIN_APP_URI, 'devices', row.id)}
                      target="_admin_app"
                      data-testid={`button-details-${row.id}`}
                    />
                  )}
                  {onDelete && (
                    <Button
                      icon={Icons.IconTrash}
                      size="default"
                      variant="tertiary"
                      onClick={() => onDelete(row.id)}
                      data-testid={`button-delete-${row.simulatedGatewayId}`}
                    />
                  )}
                </TableCell>
              )}
            </TableRow>
          ))}
        {!loading && data.length === 0 && (
          <TableRow>
            <TableCell>
              <div className={classes.content}>
                <img alt="no-results" src={noMatchesIcon} />
                <Typography variant="labelSmall" className={classes.text}>
                  {t('simulator.noGateways')}
                </Typography>
              </div>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
