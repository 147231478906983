import {
  Button,
  Card,
  createClasses,
  FormControl,
  ListItem,
} from '@kp/react-ui';
import { TFunction } from 'i18next';
import React, { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { DropdownField, TextField } from '../../../components/fields';

const classes = createClasses({
  card: {
    minHeight: 550,
  },
  actionButton: {
    marginLeft: '12px',
  },
});

export type DeviceFormData = {
  deviceId: string;
  deviceModelCapabilityId: string;
  algorithm: 'fixed' | 'sinus' | 'sawtooth' | 'rectangle' | 'triangle';
  value?: number;
  sendInterval: number;
};

type SimulatorDeviceFormProps = {
  loading: boolean;
  allDevices: Array<{
    id: string;
    name: string;
    capabilities: Array<{ id: string; name: string }>;
  }>;
  onSelectDevice: (deviceId: string) => void;
  onSubmit: () => void;
  onDiscard: () => void;
};

export const getDeviceFormInfoSchema = (
  t: TFunction,
): Yup.ObjectSchema<DeviceFormData> =>
  Yup.object().shape({
    deviceId: Yup.string()
      .required(t('general.required') || '')
      .notOneOf(['0', '-1'], t('general.required') || ''),
    deviceModelCapabilityId: Yup.string()
      .required(t('general.required') || '')
      .notOneOf(['0', '-1'], t('general.required') || ''),
    algorithm: Yup.string()
      .oneOf(['fixed', 'sinus', 'sawtooth', 'rectangle', 'triangle'])
      .required(t('general.required') || ''),
    value: Yup.number().when('algorithm', ([algorithm]) =>
      algorithm === 'fixed'
        ? Yup.number()
            .typeError(t('general.mustBeNumber') || '')
            .required(t('general.required') || '')
        : Yup.number()
            .typeError(t('general.mustBeNumber') || '')
            .optional(),
    ),
    sendInterval: Yup.number()
      .typeError(t('general.mustBeNumber') || '')
      .required(t('general.required') || '')
      .min(1, t('simulator.deviceForm.minIntervalError', { interval: 1 }) || '')
      .max(
        1440,
        t('simulator.deviceForm.maxIntervalError', { interval: 1440 }) || '',
      ),
  });

export const SimulatorDeviceForm: React.FC<SimulatorDeviceFormProps> = ({
  loading,
  allDevices,
  onSelectDevice,
  onSubmit,
  onDiscard,
}) => {
  const { t } = useTranslation();

  const { watch } = useFormContext();
  const deviceId = watch('deviceId');
  const algorithm = watch('algorithm');

  const handleSelectDevice = useCallback(
    (value: string | null) => {
      onSelectDevice(value || '0');
    },
    [onSelectDevice],
  );

  const deviceOptions = useMemo(
    () =>
      allDevices.map(({ id, name: label }) => ({
        id,
        label,
      })),
    [allDevices],
  );

  const capabilityOptions = useMemo(() => {
    const selectedDevice = allDevices.find(({ id }) => id === deviceId);
    if (!selectedDevice) {
      return [
        {
          id: '-1',
          label: t('simulator.deviceForm.noDeviceSelected'),
        },
      ];
    }
    return (selectedDevice?.capabilities || []).map(({ id, name: label }) => ({
      id,
      label,
    }));
  }, [allDevices, deviceId, t]);

  const algorithmOptions = useMemo(
    () => [
      {
        id: 'sinus',
        label: t('simulator.deviceForm.algorithms.sinus'),
      },
      {
        id: 'sawtooth',
        label: t('simulator.deviceForm.algorithms.sawtooth'),
      },
      {
        id: 'rectangle',
        label: t('simulator.deviceForm.algorithms.rectangle'),
      },
      {
        id: 'triangle',
        label: t('simulator.deviceForm.algorithms.triangle'),
      },
      {
        id: 'fixed',
        label: t('simulator.deviceForm.algorithms.fixed'),
      },
    ],
    [t],
  );

  return (
    <Card
      title={t('simulator.deviceForm.title') || ''}
      className={classes.card}
      actions={
        <>
          <Button
            onClick={onDiscard}
            variant="tertiary"
            className={classes.actionButton}
            aria-label="discard-button"
          >
            {t('general.buttons.discard')}
          </Button>
          <Button
            onClick={onSubmit}
            className={classes.actionButton}
            variant="primary"
            aria-label="save-button"
            disabled={loading}
          >
            {t('general.buttons.save')}
          </Button>
        </>
      }
    >
      <FormControl>
        <DropdownField
          id="deviceId"
          name="deviceId"
          data-testid="device-select"
          label={t('simulator.deviceForm.deviceSelect') || ''}
          placeholder={t('simulator.deviceForm.selectDevice') || ''}
          onChange={handleSelectDevice}
          disabled={loading}
        >
          {deviceOptions.map((option) => (
            <ListItem key={option.id} value={option.id} title={option.label} />
          ))}
        </DropdownField>
      </FormControl>
      <FormControl>
        <DropdownField
          id="deviceModelCapabilityId"
          name="deviceModelCapabilityId"
          data-testid="device-capability-select"
          placeholder={t('simulator.deviceForm.selectCapability') || ''}
          label={t('simulator.deviceForm.capabilitySelect') || ''}
          disabled={loading || deviceId === '0'}
        >
          {capabilityOptions.map((option) => (
            <ListItem key={option.id} value={option.id} title={option.label} />
          ))}
        </DropdownField>
      </FormControl>
      <FormControl>
        <DropdownField
          id="algorithm"
          name="algorithm"
          data-testid="algorithm-select"
          label={t('simulator.deviceForm.algorithmSelect') || ''}
          disabled={loading}
        >
          {algorithmOptions.map((option) => (
            <ListItem key={option.id} value={option.id} title={option.label} />
          ))}
        </DropdownField>
      </FormControl>
      <FormControl>
        <TextField
          id="sendInterval"
          name="sendInterval"
          type="text"
          label={t('simulator.deviceForm.sendInterval') || ''}
          fullWidth
          disabled={loading}
        />
      </FormControl>
      {algorithm === 'fixed' && (
        <FormControl>
          <TextField
            id="value"
            name="value"
            type="text"
            fullWidth
            label={t('simulator.deviceForm.value') || ''}
            disabled={loading}
          />
        </FormControl>
      )}
    </Card>
  );
};
