import React from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import { useAuth } from '@kp/react-sdk';
import { Buffer } from 'buffer';
import { createClasses } from '@kp/react-ui';

// react-scripts 5 removed the polyfills, so we need to make sure Buffer is there for SwaggerUI
if (typeof window !== 'undefined' && typeof window.Buffer === 'undefined') {
  window.Buffer = Buffer;
}

const classes = createClasses({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  voyager: {
    height: '100%',
    fontSize: 14,
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
});

export const Swagger: React.FC = () => {
  const { user } = useAuth();

  return (
    <div className={classes.root}>
      <div className={classes.voyager}>
        <SwaggerUI
          url={`${process.env.PUBLIC_URL}/openapi.json`}
          onComplete={(system) => {
            if (!user?.access_token) {
              return;
            }
            system.getStore().dispatch(
              system?.authActions.authorize({
                bearerAuth: {
                  name: 'bearerAuth',
                  schema: { type: 'http', scheme: 'bearer' },
                  value: user.access_token,
                },
              }),
            );
          }}
        />
      </div>
    </div>
  );
};
