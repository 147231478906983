import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RuleList } from './RuleList';
import { useHeader } from '../../../contexts/header-context';
import { useNotifications } from '../../../contexts/notifications-context';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { Rule, useRulesQuery } from '../../../__generated__/types';
import { RuleDetails } from './RuleDetails';

export const RuleListContainer: React.FC = () => {
  const [selectedRule, setSelectedRule] = React.useState<Rule>();

  const { t } = useTranslation();
  const { add } = useNotifications();

  const { setTitle } = useHeader();
  useEffect(() => {
    setTitle('');
  }, [setTitle, t]);

  const { loading, error, data } = useRulesQuery();
  const rules = (data?.rules?.items as Rule[]) || [];

  useBreadcrumb([
    {
      title: t('rules.breadcrumb.rules'),
      location: `/rules`,
      selected: true,
    },
  ]);

  const selectRule = (id: string) => {
    const rule = rules.find((r) => r.id === id);
    setSelectedRule(rule);
  };

  useEffect(() => {
    const message = error?.message;
    if (message) {
      add({
        type: 'danger',
        id: message,
        content: message,
      });
    }
  }, [error, add]);

  return (
    <>
      <RuleList loading={loading} rules={rules} onDetails={selectRule} />
      <RuleDetails rule={selectedRule} />
    </>
  );
};
