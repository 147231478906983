import React, { useState } from 'react';
import { Button, Card, createClasses, Icons } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import {
  ConnectionStates,
  HeartbeatStates,
} from '../../../__generated__/types';
import { Device, DeviceEditData, DeviceTable } from './DeviceTable';
import { ConfirmModal } from '../../../components/Modals';

const classes = createClasses({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: '24px',
  },
  card: {
    marginBottom: '24px',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  leftColumn: {
    width: 'calc(60% - 12px)',
  },
  rightColumn: {
    width: 'calc(40% - 12px)',
  },
});

type Gateway = {
  id: string;
  name: string;
  heartbeatState?: HeartbeatStates | null;
  connectionState?: ConnectionStates | null;
};
export type { DeviceEditData, Device };
export type SimulatorGatewayDevicesProps = {
  loading: boolean;
  gateway?: Gateway | null;
  devices: Device[];
  onAddDevice?: () => void;
  onEditDevice: (id: string, data: DeviceEditData) => void;
  onDeleteDevice: (id: string) => void;
};
export const SimulatorGatewayDevices: React.FC<
  SimulatorGatewayDevicesProps
> = ({
  loading,
  gateway,
  devices,
  onAddDevice,
  onEditDevice,
  onDeleteDevice,
}) => {
  const { t } = useTranslation();
  const [deleteId, setDeleteId] = useState<string>();

  const handleDeleteDevice = (id: string) => {
    setDeleteId(id);
  };
  const handleDeleteWarningConfirm = () => {
    if (!deleteId) return;
    onDeleteDevice(deleteId);
    setDeleteId(undefined);
  };
  const handleDeleteWarningCancel = () => {
    setDeleteId(undefined);
  };

  return (
    <>
      <div className={classes.root}>
        <Card
          title={gateway?.name || t('simulator.deleted.gateway') || ''}
          className={classes.card}
          actions={
            onAddDevice && (
              <Button
                icon={Icons.IconAdd}
                size="default"
                variant="tertiary"
                onClick={onAddDevice}
              />
            )
          }
        >
          <div className={classes.cardContent}>
            <DeviceTable
              loading={loading}
              data={devices}
              onDelete={handleDeleteDevice}
              onEdit={onEditDevice}
            />
          </div>
        </Card>
      </div>
      <ConfirmModal
        open={!!deleteId}
        title={t('simulator.deleteDeviceModal.title')}
        message={t('simulator.deleteDeviceModal.message')}
        onConfirm={handleDeleteWarningConfirm}
        onCancel={handleDeleteWarningCancel}
      />
    </>
  );
};
