import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import {
  Color,
  createClasses,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextLink,
  Typography,
} from '@kp/react-ui';

const classes = createClasses({
  heading: {
    marginTop: 16,
    fontWeight: 800,
  },
  paragraph: {
    marginTop: 8,
  },
  img: {
    maxWidth: '100%',
  },
  table: {
    margin: '16px 0',
  },
  code: {
    margin: '16px 0',
    padding: '16px',
  },
  pre: {
    margin: 0,
  },
  link: {
    display: 'inline',
    color: Color.orange,
  },
});

interface DocsMarkdownProps {
  content: string;
}

export const DocsMarkdown: React.FC<DocsMarkdownProps> = ({ content }) => {
  return (
    <ReactMarkdown
      components={{
        h1: ({ children }) => (
          <Typography className={classes.heading} variant="headlineLarge">
            {children}
          </Typography>
        ),
        h2: ({ children }) => (
          <Typography className={classes.heading} variant="headlineMedium">
            {children}
          </Typography>
        ),
        h3: ({ children }) => (
          <Typography className={classes.heading} variant="titleMedium">
            {children}
          </Typography>
        ),
        h4: ({ children }) => (
          <Typography className={classes.heading} variant="titleSmall">
            {children}
          </Typography>
        ),
        h5: ({ children }) => (
          <Typography className={classes.heading} variant="titleSmall">
            {children}
          </Typography>
        ),
        h6: ({ children }) => (
          <Typography className={classes.heading} variant="titleSmall">
            {children}
          </Typography>
        ),
        p: ({ children }) => (
          <Typography className={classes.paragraph} variant="bodyMedium">
            {children}
          </Typography>
        ),
        img: ({ alt, src }) => (
          <img alt={alt} src={src} className={classes.img} />
        ),
        table: ({ children }) => (
          <div className={classes.table}>
            <Table>{children}</Table>
          </div>
        ),
        thead: ({ children }) => <TableHead>{children}</TableHead>,
        tbody: ({ children }) => <TableBody>{children}</TableBody>,
        td: ({ children }) => <TableCell>{children}</TableCell>,
        th: ({ children }) => <TableCell>{children}</TableCell>,
        tr: ({ children }) => <TableRow>{children}</TableRow>,
        a: ({ children, href }) => (
          <TextLink href={href} className={classes.link}>
            {children}
          </TextLink>
        ),
        pre: ({ children }) => (
          <div className={classes.code}>
            <pre className={classes.pre}>{children}</pre>
          </div>
        ),
      }}
      remarkPlugins={[remarkGfm]}
    >
      {content}
    </ReactMarkdown>
  );
};
