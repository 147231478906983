import {
  DeviceSimulatorSvcDevice,
  DeviceSimulatorSvcGateway,
  DeviceSimulatorSvcGatewaysService,
} from '@kp/rest-api-javascript-sdk';

export type Device = DeviceSimulatorSvcDevice;
export type Gateway = DeviceSimulatorSvcGateway;
export type Algorithms =
  | 'fixed'
  | 'sinus'
  | 'sawtooth'
  | 'rectangle'
  | 'triangle';

/**
 * List all simulated gateways
 */
export const getSimulatedGateways =
  DeviceSimulatorSvcGatewaysService.getDeviceSimulatorApiGateways;
/**
 * Get details of a simulated gateways
 */
export const getSimulatedGateway =
  DeviceSimulatorSvcGatewaysService.getDeviceSimulatorApiGateways1;
/**
 * Add a simulated gateway
 */
export const createSimulatedGateway =
  DeviceSimulatorSvcGatewaysService.postDeviceSimulatorApiGateways;
/**
 * Update a simulated gateway
 */
type UpdateSimulatedGatewayInput = {
  gatewayId?: string;
  sharedAccessKey?: string;
  isInactive?: boolean;
};
export const updateSimulatedGateway = ({
  simulatedGatewayId,
  data,
}: {
  simulatedGatewayId: string;
  data: UpdateSimulatedGatewayInput;
}) =>
  DeviceSimulatorSvcGatewaysService.putDeviceSimulatorApiGateways(
    simulatedGatewayId,
    data,
  );
/**
 * Delete a simulated gateway
 */
export const deleteSimulatedGateway =
  DeviceSimulatorSvcGatewaysService.deleteDeviceSimulatorApiGateways;

/**
 * Add a simulated device
 */
type CreateSimulatedDeviceInput = {
  deviceIdentifier: string;
  deviceIdentifierFieldSelector: string;
  fieldSelector: string;
  algorithm: Algorithms;
  value?: number;
  sendInterval: number;
};
export const createSimulatedDevice = ({
  simulatedGatewayId,
  data,
}: {
  simulatedGatewayId: string;
  data: CreateSimulatedDeviceInput;
}) =>
  DeviceSimulatorSvcGatewaysService.postDeviceSimulatorApiGatewaysDevices(
    simulatedGatewayId,
    data,
  );

/**
 * Update a simulated device
 */
type UpdateSimulatedDeviceInput = {
  algorithm?: Algorithms;
  value?: number;
  sendInterval?: number;
};
export const updateSimulatedDevice = ({
  simulatedGatewayId,
  simulatedDeviceId,
  data,
}: {
  simulatedGatewayId: string;
  simulatedDeviceId: string;
  data: UpdateSimulatedDeviceInput;
}) =>
  DeviceSimulatorSvcGatewaysService.putDeviceSimulatorApiGatewaysDevices(
    simulatedGatewayId,
    simulatedDeviceId,
    data,
  );

/**
 * Delete a simulated device
 */
export const deleteSimulatedDevice = ({
  simulatedGatewayId,
  simulatedDeviceId,
}: {
  simulatedGatewayId: string;
  simulatedDeviceId: string;
}) =>
  DeviceSimulatorSvcGatewaysService.deleteDeviceSimulatorApiGatewaysDevices(
    simulatedGatewayId,
    simulatedDeviceId,
  );
