import React from 'react';
import { Card, createClasses } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import { Rule, RuleTable } from './RuleTable';

const classes = createClasses({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: '24px',
  },
  card: {
    marginBottom: '24px',
  },
  message: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    whiteSpace: 'pre-wrap',
  },
});

export type RuleProps = {
  loading: boolean;
  rules: Rule[];
  onDetails: (id: string) => void;
};

export const RuleList: React.FC<RuleProps> = ({
  loading,
  rules,
  onDetails,
}) => {
  const { t } = useTranslation();

  const handleDetails = (id: string) => {
    onDetails(id);
  };

  return (
    <>
      <div className={classes.root}>
        <Card
          title={t('rules.rulesTable.title') || ''}
          className={classes.card}
        >
          <RuleTable loading={loading} data={rules} onDetails={handleDetails} />
        </Card>
      </div>
    </>
  );
};
