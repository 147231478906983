import React, { useState } from 'react';
import { Card, createClasses, TextLink } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import urlJoin from 'url-join';
import { Gateway, GatewayTable } from './GatewayTable';
import { ConfirmModal } from '../../../components/Modals';
import { ADMIN_APP_URI } from '../../../utils/env';

const classes = createClasses({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: '24px',
  },
  card: {
    marginBottom: '24px',
  },
  message: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    whiteSpace: 'pre-wrap',
  },
});

export type SimulatorGatewayListProps = {
  loading: boolean;
  activating: boolean;
  gateways: Gateway[];
  orphanedSimulatedGateways: Gateway[];
  onSimulate: (id: string, simulate: boolean) => void;
  onActivate: (id: string, active: boolean) => void;
  onDetails: (id: string) => void;
};

type WarningModal = { id: string; simulate: boolean };
export const SimulatorGatewayList: React.FC<SimulatorGatewayListProps> = ({
  loading,
  activating,
  gateways,
  orphanedSimulatedGateways,
  onSimulate,
  onActivate,
  onDetails,
}) => {
  const { t } = useTranslation();
  const [showWarning, setShowWarning] = useState<WarningModal>();

  const handleSimulateWarning = (id: string) => (value: string) => {
    const simulate = value === 'simulate';
    setShowWarning({ id, simulate });
  };
  const handleSimulateWarningConfirm = () => {
    if (!showWarning) return;
    onSimulate(showWarning.id, showWarning?.simulate);
    setShowWarning(undefined);
  };
  const handleSimulateWarningCancel = () => {
    setShowWarning(undefined);
  };
  const handleDelete = (id: string) => {
    onSimulate(id, false);
  };
  const handleDetails = (id: string) => {
    onDetails(id);
  };

  const handleActivateSimulator = (id: string) => (value: string) => {
    onActivate(id, value === 'active');
  };
  return (
    <>
      <div className={classes.root}>
        {orphanedSimulatedGateways.length > 0 && (
          <Card
            title={t('simulator.gatewaysTable.orphanedTitle') || ''}
            className={classes.card}
          >
            <GatewayTable
              loading={loading}
              activating={activating}
              data={orphanedSimulatedGateways}
              onDetails={handleDetails}
              onDelete={handleDelete}
            />
          </Card>
        )}

        {gateways.length > 0 && (
          <Card
            title={t('simulator.gatewaysTable.title') || ''}
            className={classes.card}
          >
            <GatewayTable
              loading={loading}
              activating={activating}
              data={gateways}
              onDetails={handleDetails}
              onChangeSimulated={handleSimulateWarning}
              onChangeActive={handleActivateSimulator}
            />
          </Card>
        )}
        <Card className={classes.card}>
          <div className={classes.message}>
            {t('simulator.gatewaysTable.createHintPart1')}{' '}
            <TextLink href={urlJoin(ADMIN_APP_URI, 'devices')}>
              Admin App
            </TextLink>
          </div>
        </Card>
      </div>
      <ConfirmModal
        open={!!showWarning}
        title={
          showWarning?.simulate
            ? t('simulator.simulateModal.title')
            : t('simulator.deleteModal.title')
        }
        message={
          showWarning?.simulate
            ? t('simulator.simulateModal.message')
            : t('simulator.deleteModal.message')
        }
        onConfirm={handleSimulateWarningConfirm}
        onCancel={handleSimulateWarningCancel}
      />
    </>
  );
};
