import React from 'react';
import { Card, Colors, Typography, createClasses } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { FormattedDate } from '../../../components/FormattedDate';

const classes = createClasses({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: '24px',
  },
  header: {
    marginTop: 16,
    marginLeft: 24,
    color: Colors.Neutral.textWeak,
  },
  value: {
    marginTop: 4,
    marginLeft: 24,
    color: Colors.Neutral.text,
  },
  jsonValue: {
    whiteSpace: 'pre-wrap',
  },
});

export type RuleDetailsProps = {
  rule?: {
    id: string;
    name?: string | null;
    json?: string | null;
    createdAt?: string | null;
    deployedAt?: string | null;
  };
};

type EntryProps = {
  title: string;
  value: React.ReactNode;
  valueClassName?: string;
};
const Entry: React.FC<EntryProps> = ({ title, value, valueClassName }) => {
  return (
    <>
      <Typography
        variant="labelSmall"
        className={classes.header}
        component="dt"
      >
        {title}
      </Typography>
      <Typography
        variant="bodyMedium"
        className={classNames(classes.value, valueClassName)}
        component="dd"
      >
        {value}
      </Typography>
    </>
  );
};

export const RuleDetails: React.FC<RuleDetailsProps> = ({ rule }) => {
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Card title={t('rules.ruleDetails.title') || ''}>
        {rule ? (
          <>
            <Entry title={t('rules.ruleDetails.name')} value={rule.name} />
            <Entry title={t('rules.ruleDetails.id')} value={rule.id} />
            <Entry
              title={t('rules.ruleDetails.createdAt')}
              value={<FormattedDate value={rule.createdAt} />}
            />
            <Entry
              title={t('rules.ruleDetails.deployedAt')}
              value={
                rule.deployedAt ? (
                  <FormattedDate value={rule.deployedAt} />
                ) : (
                  '-'
                )
              }
            />
            <Entry
              title={t('rules.ruleDetails.json')}
              value={rule.json ?? t('rules.ruleDetails.noJson')}
              valueClassName={classes.jsonValue}
            />
          </>
        ) : (
          <div>{t('rules.ruleDetails.noRuleSelected')}</div>
        )}
      </Card>
    </div>
  );
};
