import React from 'react';
import { useTranslation } from 'react-i18next';
import { NotFoundPage } from '@kp/react-ui';

interface NotFoundInterface {
  navigateToHome: () => void;
}

export const NotFound: React.FC<NotFoundInterface> = ({ navigateToHome }) => {
  const { t } = useTranslation();

  return (
    <NotFoundPage
      data-testid="404"
      title={t('errors.notFound.title')}
      subtitle={t('errors.notFound.subtitle')}
      buttonText={t('errors.notFound.button')}
      buttonOnClick={navigateToHome}
    />
  );
};
